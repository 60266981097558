.fas {
    margin-right: 3px;
}

a {
    color: rgb(0, 0, 238);
}

.contactItem, .sideContactItem {
    display: inline-block;
    /* padding-right: 0.8rem; */
    padding: 0.8rem;
    margin: 0 auto;
}

.contactItem a {
    color: white;
}  

/* .mail {
    padding-left: 0.9rem;
} */

.copyRight {
    text-align: center;
}

.copyRight p {
    font-size: 0.7rem;
}

.MainNav {
    position: absolute;
    bottom: 0;
    text-align :center;
    width: 100%;
    color: white;
    font-weight: 500;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    line-height: 0.6rem;
    background-color: rgb(0, 154, 189);
}

.MainNavLong {
    position: relative;
    bottom: 0;
    text-align :center;
    width: 100%;
    color: white;
    font-weight: 500;
    margin-top: 2rem;
    padding: 0.5rem 1rem;
    line-height: 0.6rem;
    background-color: rgb(0, 154, 189);
}

.SideNav {
    text-align :center;
    width: 100%;
    /* color: white; */
    font-weight: 500;
    margin-top: 1.5rem;
    line-height: 0.6rem;
}

footer ul {
    margin-block-start: 0em;
    margin-block-end: 0rem;
    padding: 0px;
}

li {
    list-style: none
}