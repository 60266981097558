html {
    height: 100%;
    background-color: #fafafa;
}

body {
    margin: 0 auto;
    font-family: 'Helvetica Neue', 'Lato', 'Roboto', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
    line-height: 1.5rem;
    text-align: center;
    color: #373a36;
    box-sizing: border-box;
    overflow-y: visible;
}

h4 {
    margin: 0;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

figcaption {
    font-size: 0.7rem;
}

ul {
    padding: 0;
}

.red {
    color: red;
    font-weight: bold;
}

.nav a {
    display: inline-block;
}

.Comment {
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: 1.2rem;

}

.Comment__userInfo {
    font-size: 0.8rem;
    font-weight: bold;
    color: rgb(0, 154, 189);
}

.Comment__date p {
    font-size: 13px;
    margin-bottom: 0;
    color: #797C81;
    margin-top: 0.4rem;
}

.Comment__btn-div {
    font-size: 1rem;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
}

.modal-main {
    position:fixed;
    background: #fafafa;
    width: 100%;
    height: 100%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.modal-mainTwo {
    position:fixed;
    background: #fafafa;
    width: 100%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.closeModalDiv {
    text-align: right;
}

.closeModalDiv button {
    background: #fafafa;
    border: 0;
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    cursor: pointer;
}

.EditModal__form {
    max-width: 480px;
    padding: 0.75rem;
    margin: 0 auto;
}

.EditModal__buttonsDiv {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.EditModal__likesDiv {
    color : #A6AAB0;
}

.Post__likeButton {
    margin-right: 0.8rem;
    font-size: 1rem;
    border: none;
    color: #A6AAB0;
}
.Post__likeButton:hover {
    color: rgb(224, 36, 94);
}

.Post__userInfo {
    color: rgb( 0, 154, 189);
    font-size: 0.9rem;
}

.selectBox select, .subjectLabel input {
    font-size: 1rem;
    width: 100%;
}

.LoginForm__signupLabel textarea {
    width: 100%;
    height: 120px;
    padding: 0.5rem;
    font-size: 1rem;
}

.heartColor {
    color: rgb(224, 36, 94);
}

.heartColor #text {
    color: rgb(224, 36, 94);
}

.fa-edit {
    margin-left: 0.2rem;
    color: rgb(0, 154, 189);
}

.fa-search, .fa-lock, .fa-city {
    font-size: 4rem;
    color: rgb(0, 154, 189);
}

.fa-heart {
    margin-right: 0.4rem
}

.fa-camera {
    font-size: 32px;
}

.fa-comment {
    top: 0;
    bottom: 0;
    right: 1rem;
    font-size: 20px;
    margin-right: 0.4rem
}

.fa-edit:hover {
    color:  #006b84;
}

.fa-trash-alt {
    color: #A6AAB0;
    margin-left: 0.8rem;
}

.fa-trash-alt:hover {
    color: #d11a2a;
    cursor: pointer;
}

.fa-home {
    margin-right: 0.4rem;
}

.fa-image {
    width: 2rem;
    font-size: 1.25rem;
}

.fa-bowling-ball {
    font-size: 32px;
    animation: spin 1.2s infinite linear;
}

.scroll-up-div a {
    display: block;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999999999;
    color: #eee;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all .25s ease;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}

.scroll-up-div a:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.Post__commentContainer {
    color: #A6AAB0;
}

.MemberProfiles__user a:hover {
    color: rgb(0, 154, 189);
}

.Post__commentNumber {
    right: 1.7rem;
    font-weight: bold;
}

.MyProfile {
    text-align: center;
}

.MyProfile h1 {
    font-size: 1.7rem;
    padding-left: 4%;
    padding-right: 4%;
}

.MyProfile__userContainer h2 {
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: 0;
}

.rolling {
    animation: move 6s ease-out;
    filter: drop-shadow(4px 4px 4px #999);
    position: absolute;
    top: 50%;
    left: 50%;
    overflow-y: hidden;
}

.spinner {
    animation: spin 1.2s infinite linear;
}

.fadein {
    animation: fadein 2s;
}

@keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}
  
@-webkit-keyframes spin {
    0%  {
        -webkit-transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg)
    }   
}

.LandingPage__descriptOne, .LandingPage__descriptTwo {
    padding-bottom: 2rem;
}

.astrik {
    color: #ed5868;
    margin: 0 0 0 3px;
}

.CreateAccount, .LoginPage, .ProfilePage, .MyProfile {
    margin-top: 5rem;
}

.MemberProfiles {
    padding-top: 5rem;
}

.Dashboard {
    padding-top: 5rem;
}

.Nav__HeaderNotIn {
    flex: 1 0 auto;
}

.Nav__HeaderSpace {
    margin: 0.5rem;
}

.MyProfile__headers {
    border-bottom: 2px solid #c3c3c3;
   
}

.MyProfile__headers h3 {
    margin-bottom: 0;
    margin-top: 1rem;
    padding-left: 4%;
    padding-right: 4%;
}

.MyProfile__headers p {
    margin: 0;
    padding-left: 4%;
    padding-right: 4%; 
}

.MyProfile button {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    margin-right: 4%;
    margin-left: 4%;
}

.Profile__locationBtn {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.EditImage__Container {
    text-align: center;
}

.MyProfile h3 {
    font-size: 20px;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.list-section {
    padding-left: 4%;
    padding-right: 4%;
    border-top: 8px solid #EEE;
    padding-top: 1rem;
}

.list-section i {
    margin-right: 0.7rem;
}


.MyProfile__inputContainer {
    margin-top: 2rem;
    padding-left: 4%;
    padding-right: 4%;
}

#showProfiles {
    margin-left: 0.5rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.LoginForm {
    width: 100%;
    max-width: 420px;
    padding: 1rem 1rem 0;
    margin: auto;
}

.MyProfile__userContainer {
    width: 100%;
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin: auto;
}

.LoginForm__signupLabel {
    text-align: left;
    margin-bottom: 0.3rem;
}

.LoginForm__signupLabel > input {
    width: 100%;
    padding: 0.2rem 0.4rem;
    border-radius: 0.2rem;
    font-size: 0.85rem;
}

.LoginForm__signupLabel > label {
    font-weight: bold;
}

.LoginForm__signupElement {
    text-align: left;
    margin-bottom: 1rem;
}

.LoginForm__redirect {
    margin-top: 1rem;
}

.LoginForm__loginDemo p  {
    margin: 0;
    font-size: 0.9rem;
}

.LoginForm__signupDemo p {
    margin: 0;
    font-size: 0.95rem;
    color: red;
}

.post_btn {
    color: white;
    font-weight: 400;
    border-radius: 3px;
    width: auto;
    border-width: 2px;
    padding: 0.6rem 1.3rem;
    border: none;
    cursor: pointer;
    background-color: rgb(0, 154, 189);
    font-size: 1rem;
}

.btn {
    display: inline-block;
    background-color: rgb(0, 154, 189);
    color: #fff;
    font-weight: 500;
    border-radius: 3px;
    border-width: 2px;
    padding: 0.5rem 0.8rem;
    border: none;
    font-size: 0.9rem;
}

.btn_signIn {
    width: 100%;
    margin: 1.2rem auto;
}

.LoginForm__redirect a {
    color: rgb(0, 154, 189);
    font-weight: 500;
}

.register_btn {
    display: inline-block;
    background-color: rgb(0, 154, 189);
    color: #fff;
    font-weight: 500;
    border-radius: 3px;
    width: 90%;
    border-width: 2px;
    padding: 0.45rem;
}

.register_btn:hover, .btn:hover, .post_btn:hover {
    background-color: #006b84;
    cursor: pointer;
}

.login_btn {
    display: inline-block;
    background-color: white;
    color: rgb(0, 154, 189);
    font-weight: 500;
    border-radius: 3px;
    width: 90%;
    border-width: 2px;
    padding: 0.45rem;
    border: 1px solid rgb(0, 154, 189);
}

.login_btn:hover {
    background-color: #eaf9fd;
}

.LandingPage__descriptContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 2.5rem;
}

.LandingPage__header {
    background: linear-gradient(rgba(0,0,0, 0.7), rgba(0, 0, 0, 0.7)), url('../../app-images/highway-landing.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.LandingPage__header h1 {
    font-size: 22px;
}

.LandingPage__header p {
    font-size: 15px;
}

.CommentTextBox__container {
    border: 1px solid #EEE;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.CommentTextBox__textArea {
    width: 100%;
    border: none;
    border-bottom: 2px solid #EEE;
    padding: 0.5rem;
    font-size: 12px;
}

.CommentTextBox__buttonDiv {
    text-align: center;
    padding-top: 0.1rem;
    padding-bottom: 0.5rem;
}

.LandingPage__btnContainer {
    margin-bottom: 0.8rem;
    width: 90%;
    max-width: 250px;
}

.LandingPage__btnReminder {
    margin-bottom: 0.8rem;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
}

.Dashboard__addSelect {
    display:block;
}

.ProfilePage__addSelect {
    width: 30%;
}

.ProfilePage__addSelect:not(:last-child) {
    margin-right: 0.5rem;
}

.MemberProfiles__info {
    font-size: 14px;
    margin-right: 0.8rem;
}

.MemberProfiles__info h2 {
    margin: 0;
}

.MemberProfiles__locationCite {
    margin: 0;
    font-size: 12px;
}

.MemberProfiles__flexBox {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.MemberProfiles__image {
    display: flex;
    align-items: center;
}

.image-upload {
    margin: 0 auto;
    position: relative;
}

.MyProfile__image {
    padding-left: 4%;
    margin-bottom: 0.3rem;
    padding-right: 4%;
    text-align: left;
}

.image-text {
    visibility: hidden;
    position: absolute;
    top: 33%;
    left: 40%;
    opacity: 0;
}

.image-upload:hover .image-text {
    visibility: visible;
    opacity: 1;
}

.PostPage__postContainer {
    border-bottom: 4px solid #EEE;
    padding-left: 4%;
    padding-right: 4%;
}

.menu__toggle {
    display: initial;
}

.DashNav {
    display: none;
}

/* Button styling */
.menu-toggle {
  display: inline-block;
  padding: .75em 15px;
  line-height: 1em;
  font-size: 1em;
  color: #333;
}

.menu-toggle:hover,
.menu-toggle:focus {
  color: #c3c3c3;
}

.main-menu {
  position: absolute;
  display: none;
  left: -200px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  transition: left 0.3s ease, box-shadow 0.3s ease;
  z-index: 999;
  background: white;
}

.main-menu ul {
  list-style: none;
  margin: 0;
  width: 200px;
  background: white;
}

.main__ul {
    padding: 2.5rem 0 0;
}

.second__ul {
    border-bottom: 4px solid #EEE;
}

.btn-div {
    font-size: 20px;
}

#main-menu-close {
    padding: 0.5rem;
}

.Post:hover {
    background: #F7FCFE;
}

.main__ul a {
    display: block;
    padding: 1.1rem;
    line-height: 1em;
    font-size: 1em;
    color: #373a36;
    text-decoration: none;
}

.second__ul a {
    display: block;
    padding: 1rem 15px;
    line-height: 1em;
    font-size: 1em;
    color: #373a36;
    text-decoration: none;
}

.main-menu li:first-child a {
  border-top: 4px solid #EEE;
  padding-top: 1rem;
}

.AddPost__submitDiv {
    position: relative;
    color: rgb(0, 154, 189);
}

.AddPost__fileInputLabel {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 90%;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 0;
}

.AddPost__fileInput {
    width: 100%;
    height: 90%;
    cursor: pointer;
}

.mainContent {
    min-height: calc(100vh - 79px);
    position: relative;
}

.AddPost__submitContainer {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
}

.main__ul i, .second__ul i {
    margin-right: 0.9rem;
}

.main-menu a:hover,
.main-menu a:focus {
  color: rgb(0, 154, 189);
  background: rgba(0, 0, 0, 0.10);
}

.back-btn {
    display: inline-block;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}

.PostPage__header {
    display: inline-block;
    padding-right: 6rem;
}

.PostPage__header h4 {
    display: inline-block;
}

.back-btn a  {
    display: block;
    line-height: 1rem;
    font-size: 1rem;
    color: rgb(0, 154, 189);
    text-decoration: none;
    border-radius: 50%;
}

.back-btn:hover {
    background: rgba(0, 0, 0, 0.10);
}

.main-menu .menu-close {
  position: absolute;
  right: 0;
  top: 0;
}

.main-menu:target,
.main-menu[aria-expanded="true"] {
  display: block;
  left: 0;
  outline: none;
  -moz-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  -webkit-box-shadow: 3px 0 12px rgba(0,0,0,.25);
  box-shadow: 3px 0 12px rgba(0,0,0,.25);
}

.main-menu:target .menu-close,
.main-menu[aria-expanded="true"] .menu-close {
  z-index: 1001;
}

.main-menu:target ul,
.main-menu[aria-expanded="true"] ul {
  position: relative;
  z-index: 1000;
}

.main-menu:target + .backdrop,
.main-menu[aria-expanded="true"] + .backdrop{
  position: absolute;
  display: block;  
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(0,0,0,0.5);
  cursor: default;
}

@supports (position: fixed) {
  .main-menu,
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop{
    position: fixed;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.Nav__headerReg {
    padding: 0.8rem 0.5rem;
    display: flex;
    align-items: baseline;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgb(0, 154, 189);
    width: 100%;
    z-index: 999;
}

.Nav__header {
  padding: 0.8rem 0.5rem;
  display: flex;
  align-items: baseline;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 154, 189);
  width: 100%;
  z-index: 999;
}

article {
  padding: 30px;
  width: 55em;
  font-size: 16px;
  line-height: 1.5em;
}

article h2 {
  font-weight: 500;
  font-size: 28px;
}

.logo {
  margin: 0 auto;
  font-size: 1.25rem;
  cursor: pointer;
}

.MyProfile__userImage {
    text-align: left;
}

.user__image {
    border-radius: 50%;
    text-align: left;
}

.MemberProfiles__user {
    border: 1px solid #EEE;
    margin: 1rem;
    padding: 1rem;
}

.Post {
    text-align: left;
    border-top: 0.8rem solid #EEE;
    border-bottom: 2px solid #C3C3C3;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 0.5rem;
    padding-bottom: 0.8rem;
}

.PostPage {
    text-align: left;
    padding-top: 4rem;
}

.Dashboard__browseInput {
    flex: 1 1;
    margin-bottom: 1.5rem;
}

.Dashboard__browseInput h4 {
    font-size: 1.2rem;
}

.Dashboard__browseInput div {
    width: 60%;
    margin: 0 auto;
}

.MemberProfiles__user a {
    margin-top: 0.5rem;
}

.Dashboard__addCity h4 {
    font-size: 0.95rem;
}

.Dashboard__addCityForm {
    width: 70%;
    margin: 0 auto;
}

select {
    display: inline-block;
}


.selectContainer {
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    overflow: hidden;
    background-color: #fff;
    background: #fff;
    position: relative;
    margin-bottom: 0.5rem;
}

.selectContainer:after {
    top: 50%;
    left: 90%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}

.selectContainer select {
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.selectContainer select:focus {
    outline: none;
}

.selectModalDiv {
    padding: 0;
    border: none;
    border-style: inset;
    border-radius: 0.2rem;
    overflow: hidden;
    background-color: #fff;
    background: #fff;
    position: relative;
    margin-bottom: 0.5rem;
}

.selectModalDiv:after {
    top: 50%;
    left: 90%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(0, 0, 0, 0);
    border-top-color: #000000;
    border-width: 5px;
    margin-top: -2px;
    z-index: 100;
}

.selectModalDiv select {
    padding: 5px 8px;
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.selectModalDiv select:focus {
    outline: none;
}

.Dashboard__browseContainer {
    width: 90%;
    margin: 0 auto;
}

.Dashboard__addCity {
    flex: 1 1;
}

.Dashboard__addCity h4, .Dashboard__browseInput h4 {
    margin-bottom: 0.4rem;
}

a {
    text-decoration: none;
    color: #373a36;
}

.Post a:hover {
    text-decoration: underline;
}

.Post h4, .PostPage h4 {
    font-size: 1.2rem;
}

.Post__date p {
    font-size: 13px;
    color: #797C81;
}

.Post__body p {
    font-size: 0.93rem;
}

.Post__body img {
    width: 100%;
    max-height: 400px;
    max-width: 480px;
}

h3 a {
    color: white;
}

#AddPost__cover {
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.5);
    z-index: 100;
    width:100%;
    height:100%;
    display:none;
}

.main__ul {
    border-bottom: 4px solid #EEE;
}

#createPostForm {
  width: 100%;
  background-color: white;
  padding: 3rem 1.5rem 1.5rem;
  margin:0 auto;
  position:relative;
  z-index:999;
  display:none;
  border:5px solid #cccccc;
  border-radius:10px;
}

#createPostForm:target, #createPostForm:target + #AddPost__cover{
    display:block;
    opacity:2;
    overflow-y: hidden;
}

.cancelForm {
  display:block;
  position:absolute;
  top:3px;
  right:2px;
  color:#373a36;
  font-size:1.25rem;
  text-decoration:none;
  text-align:center;
  font-weight:bold;
  padding: .75rem;
  line-height: 1rem;
}

.cancelForm:hover, .cancelForm:focus {
    background: rgba(0, 0, 0, 0.10);
}

.AddPost {
    margin: 4rem 0.5rem 1rem;
}

.Dashboard__submit {
    padding: 0.2rem 0.4rem;
}

.AddPost__imageContainer {
    text-align: left;
}

.MyProfile__userInfo {
    margin-top: 2rem;
}

.image-upload button, .image-upload {
    width: 175px;
    height: 175px;
}

.MyProfile__hoverDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 3px dotted #EEE;
    height: 175px;
    width: 175px;
    color: rgb(0, 154, 189);
}

.MyProfile__hoverCamera {
    margin-bottom: 0.5rem;
}


/* Responsive */
@media only screen and (min-width: 480px) {
    .Dashboard__browseInput h4 {
        font-size: 1.4rem;
    }

    .Dashboard__browseInput div {
        width: 50%;
    }

    .Dashboard__addCity {
        margin-top: 2.5rem;
    }

    .Dashboard__addCity h4 {
        font-size: 1.2rem;
    }

    .Dashboard__browseInput h4, .Dashboard__addCity h4 {
        margin-bottom: 0.6rem;
    }

    .Dashboard__addCityForm {
        width: 75%;
    }

    .DashMainPosts__header h2 {
        font-size: 28px;
    }

    .selectModalDiv select {
        font-size: 13px;
    }

    .selectContainer select {
        font-size: 12px;
    }
}

@media only screen and (min-width: 640px) {
    .AddPost__formContainer {
        display: flex;
        justify-content: space-between;
    }

    .Dashboard__addCity {
        margin-top: 0;
    }

    .Dashboard__addCity h4 {
        font-size: 1.1rem;
    }

    .Dashboard__browseInput h4 {
        font-size: 1.25rem;
    }

    .DashMainPosts__header {
        text-align: left;
        margin-left: 2.5%;
        margin-right: 2.5%;
    }

    .AddPost {
        margin: 0;
    }

    .EditModal__form {
        padding: 0;
        padding-top: 0.8rem !important;
        padding-bottom: 0.8rem;
    }

    .Dashboard {
        padding-top: 6rem;
    }

    .selectContainer, .Dashboard__addCityForm, .Dashboard__browseInput div {
        margin: 0;
    }

    .selectContainer {
        margin-bottom: 0.5rem !important;
        max-width: 400px;
        margin: 0 auto;
    }

    .Dashboard__browseContainer {
        width: 95%;
        display: flex;
        text-align: left;
    }
    
    .modal-main {
        border: 1px solid #EEE;
        border-radius: 6px;
        width: 80%;
        height: 85%;
    }

    .modal-mainTwo  {
        border: 1px solid #EEE;
        border-radius: 6px;
        width: 80%;
        max-width: 700px;
    }

    .modalSelect {
        flex: 1;    
    }

    .modalSelect:first-child {
        margin-right: 0.5rem;
    }

    .MemberProfiles__image img {
        width: 150px;
        height: 150px;
    }

    .messageAdd input {
        flex: 1
    }

    .AddPost__formDiv label {
        margin-right: 0.6rem;
    }

    .AddPost__form {
        width: 95%;
        margin: 0 auto;
        margin-bottom: 5rem;
    }

    .AddPost__formDiv {
        flex: 1;
        display: flex;
    }

    .AddPost__formDiv select, .AddPost__formDiv input {
        flex: 1
    }

    .AddPost__formDiv:not(:last-child) {
        margin-right: 1.5rem;
    }

    .messageAdd {
        text-align: left;
        display: flex;
        margin-bottom: 0.7rem;
        margin-right: 0;
    }

    .messageAdd label {
        margin-right: 0.6rem;
    }

    .LandingPage__header {
        background: linear-gradient(rgba(0,0,0, 0.6), rgba(0, 0, 0, 0.6)), url('../../app-images/highway-landing.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
    }

    .MyProfile__userContainer {
        text-align: center;
        max-width: 500px;
        border: 1px solid #EEE;
    }

    .modal-main h3 {
        margin-bottom: 1rem;
        text-align: center;
    }

    .Profile__locationBtn {
        width: 400px;
        font-size: 1rem;
    }

    .scroll-up-div a {
        bottom: 15px;
        right: 15px;
    }   
}

@media only screen and (min-width: 768px) {

    .Dashboard__scroll {
        display: none;
    }

    .LandingPage__descriptContainer {
        display: flex;
        width: 95%;
        max-width: 1000px;
    }

    .LandingPage__descriptOne {
        margin-right: 1.5rem;
    }

    .LandingPage__descriptTwo {
        margin-right: 1.5rem;
    }

    .LandingPage__descriptTwo:last-of-type {
        margin-right: 0;
    }

    .MyProfile, .MemberProfiles {
        margin-top: 4rem;
    }

    .MemberProfiles{
        padding: 0;
    }

    .main__ul {
        border-bottom: none;
    }

    .Nav__footer {
        display: none;
    }

    .DashMainPosts__header {
        display: flex;
        margin-top: 0.5rem;
        margin-left: 1.5%;
        margin-bottom: 1.2rem;
    }

    .DashMainPosts__header h2 {
        margin: 0;
        margin-top: 0.2rem;
        font-size: 2.4rem;
        flex: 1 1;
        text-align: center;
    }

    .Dashboard__browseInput {
        flex: 0;
    }
  
    #createPostForm {
      top: 100%;
    }

    select {
        margin: 0;
        margin-top: 3px;
    }

    .logo {
        margin-left: 0.5rem;
    }

    .main-menu li:first-child a {
        padding: 0.5rem;
    }

    .main-menu li a {
        padding: 0.5rem;
    }

    .Dashboard__browseContainer {
        display: flex;
        margin: 0;
        margin-top: 4rem;
        justify-content: flex-start;
        flex-direction: column;
        flex: 1 1 26%;
        border-top: 1px solid #EEE;
        padding: 1.3rem 1rem;
        background: linear-gradient(to left,#fefefe, 99%, #8c8c8d);
    }

    .Dashboard__Main {
        flex: 1 1 74%;
    }

    .Dashboard__browseInput div {
        width: 100%;
    }

    .Dashboard__addCityForm {
        width: 100%;
    }

    .Dashboard__browseInput {
        margin: 0;
        margin-bottom: 1.5rem;
        flex: 0;
    }

    .Dashboard__browseInput h4 {
        margin-right: 0.5rem;
        font-size: 1.1rem;
    }

    .Dashboard__addCity h4 {
        margin-right: 0.5rem;
        font-size: 1rem;
    }

    .Dashboard__addCity {
        flex: 0;
    }

    .selectContainer {
        margin: 0;  
    }

    .MyProfile__imageSelect {
        margin: 0 auto;
    }

    .selectContainer select {
        font-size: 11px;
    }

    .selectButtonContainer {
        margin-top: 0.8rem;
    }
    
    body {
        overflow-y: hidden;
    }

    .PostPage {
        overflow-y: auto;
        max-width: 700px;
        margin: 0 auto;
        padding: 0;
        border: 1px solid #EEE;
        margin-top: 4.5rem;
    }

    .Nav__logout {
        display: none;
    }
   
    .DashContainer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: calc(100vh - 130px);
        border: 1px solid #EEE;
    }

    .MyProfile__userContainer, .MemberContainer {
        max-width: 900px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: calc(100vh - 64px);
        border: 1px solid #EEE;
    }

    .MemberProfiles__user:first-child {
        margin-top: 0;
    }

    .MemberProfiles__user {
        border: 0;
        border-top: 8px solid #EEE;
        border-bottom: 2px solid #c3c3c3;
        margin: 0;
    }

    .MemberProfiles__user:first-child {
        border-top: 1px solid #EEE;
    }

    .DashNav {
        display: flex;
        flex: 1 1 25%;
        flex-flow: column nowrap;
        text-align: center;
        z-index: 30;
        background: #061e2a;
        background: linear-gradient(to right,#fefefe, 98%, #8c8c8d);
        justify-content: flex-start;
        overflow-y: auto;
    }

    .menu-container {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        padding: 0.75rem;
        border-bottom: 3px solid #eee;
    }

    .menu-container:hover, .menu-container:focus {
        background: rgba(0, 0, 0, 0.10);
    }

    .main-nav-link {
        transition: opacity .2s, visibility .2s;
        flex: 1 1 auto;
        text-align: left;
        display: flex;
        position: relative;
    }

    .DashNav__folderLink {
        font-weight: 500;
        font-size: 0.85rem;
        letter-spacing: 0.04rem;
        color: rgb(0, 154, 189);
        padding: 0.75rem 0.5rem;
        text-transform: uppercase;
    }

    .DashMainPosts {
        flex: 1 1 75%;
        overflow-y: auto;
    }

    .MemberProfiles__userList {
        flex: 1 1 75%;
        overflow-y: auto;
        margin-top: 0;
    }

    .MyProfile__userInfo {
        flex: 1 1 75%;
        overflow-y: auto;
        margin-top: 1rem;
        padding: 1rem;
    }

    .menu-toggle,
    .main-menu .menu-close {
        display: none;
    }

    .main-menu ul {
        font-weight: bold;
        font-size: 0.9rem;
        width: auto;
        background: rgb(0, 154, 189);
    }
  
    .main-menu {
        margin-right: 0.5rem;
        position: relative;
        left: auto;
        top: auto;
        height: auto;
        display: block;
    }
    
    .main__ul {
        display: flex;
        padding: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
                box-shadow: none;
        height: auto;
        width: auto;
        background: none;
    }

    .second__ul {
        display: none;
    }
    
    .main-menu a {
        color: white;
        border: 0 !important; 
    }
    
    .main-menu a:hover,
    .main-menu a:focus,
    .main__ul li:hover {
        cursor: pointer;
        background: none;
        color: white;
    }

    .fa-home {
        margin-right: 0.5rem;
    }

    .Dashboard {
        padding-top: 4.3rem;
        flex-direction: row-reverse;
        display: flex;
    }

    .modal-main {
        width: 65%;
    }

    @media only screen and (min-width: 950px) {
        .Nav__header {
            padding-left: 4%;
            padding-right: 4%;
        }

        .modal-main {
            width: 58%;
        }
    }

    @media only screen and (min-width: 1030px) {
        .Nav__header {
            padding-left: 7%;
            padding-right: 7%;
        }
    }

    @media only screen and (min-width: 1110px) {
        .Nav__header {
            padding-left: 10%;
            padding-right: 10%;
        }
    }

    @media only screen and (min-width: 1190px) {
        .Nav__header {
            padding-left: 13%;
            padding-right: 13%;
        }
    }

    @media only screen and (min-width: 1270px) {
        .Nav__header {
            padding-left: 16%;
            padding-right: 16%;
        }
    }

    @media only screen and (min-width: 1350px) {
        .Nav__header {
            padding-left: 19%;
            padding-right: 19%;
        }
    } 
    @media only screen and (min-width: 1430px) {
        .Nav__header {
            padding-left: 22;
            padding-right: 22;
        }
    } 

    @media only screen and (min-width: 1510px) {
        .Nav__header {
            padding-left: 25%;
            padding-right: 25%;
        }
    } 
    @media only screen and (min-width: 1590px) {
        .Nav__header {
            padding-left: 28%;
            padding-right: 28%;
        }
    } 
}


